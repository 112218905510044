import { useApolloClient } from '@apollo/client';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import useCommunitySsoProperties from '@aurora/shared-client/components/community/useCommunitySsoProperties';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import AuthFlowContext from '@aurora/shared-client/components/context/AuthFlowContext/AuthFlowContext';
import SsoRegistrationContext from '@aurora/shared-client/components/context/SsoRegistrationContext/SsoRegistrationContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import useSeoProperties from '@aurora/shared-client/components/seo/useSeoProperties';
import useAuthFlow from '@aurora/shared-client/components/useAuthFlow';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import usePostPageRoute from '@aurora/shared-client/components/usePostPageRoute';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { isNodeBoard, isNodeGroupHub } from '@aurora/shared-client/helpers/nodes/NodeUtils';
import type {
  GroupHubMessagePostPagesAndParams,
  MessagePostPagesAndParams
} from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { AuthFlow } from '@aurora/shared-client/types/enums';
import type { Board, CoreNodeEdge } from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ConversationStyle,
  MembershipType
} from '@aurora/shared-generated/types/graphql-schema-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import type { TextAlignment } from '@aurora/shared-types/texts/enums';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { getLog } from '@aurora/shared-utils/log';
import type { GraphQLFormattedError } from 'graphql';
import React, { useCallback, useContext, useRef } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { useUIDSeed } from 'react-uid';
import ConversationStyleBehaviorHelper from '../../../helpers/boards/ConversationStyleBehaviorHelper';
import { MembershipAction } from '../../../types/enums';
import type {
  GroupHubDetailsQueryVariables,
  JoinNodeMutation,
  JoinNodeMutationVariables
} from '../../../types/graphql-types';
import EditableWidget from '../../common/Widget/EditableWidget';
import type { WidgetFC, WidgetProps } from '../../common/Widget/types';
import { isNodeAbleToDisplayActionButton } from '../../community/NodeBannerWidget/NodeBannerWidgetHelper';
import EmailVerificationContext from '../../context/EmailVerificationContext/EmailVerificationContext';
import useGroupHubDetails from '../../grouphubs/useGroupHubDetails';
import joinNodeMutation from '../../memberships/JoinMembershipNode.mutation.graphql';
import { updateNode } from '../../memberships/MembershipActionHelper';
import useTranslation from '../../useTranslation';
import { ButtonStyle } from '../NodeActionButtonWidgetEditor/NodeActionButtonEditor';
import localStyles from './NodeActionButtonWidget.module.pcss';
import { useActionButtonWidgetFinalProps } from './NodeActionButtonWidgetHelper';

const log = getLog(module);

export enum ButtonSize {
  FULL_WIDTH = 'full_width',
  CONTENT_WIDTH = 'content_width'
}
export interface NodeActionButtonProps extends WidgetProps {
  /**
   * Action button title
   */
  actionButtonTitle?: string;

  /**
   * How the button should align within its container
   */
  buttonAlignment?: TextAlignment;

  /**
   * How wide the button should be
   */
  buttonWidth?: ButtonSize;

  /**
   * The style of the button
   */

  buttonStyle?: ButtonStyle;
}

/**
 * Node action button
 *
 * @author Matt Vrydaghs
 */
const NodeActionButtonWidget: WidgetFC<NodeActionButtonProps> = props => {
  const cx = useClassNameMapper(localStyles);
  const { contextNode } = useContext(AppContext);
  const { userContext } = contextNode;
  const tenant = useContext(TenantContext);
  const { addToast } = useToasts();
  const {
    registrationStatus,
    isAnonymous,
    isPartiallyRegistered,
    isFullyRegistered,
    confirmEmailStatus
  } = useRegistrationStatus();
  const { showAuthenticationModal } = useContext(AuthFlowContext);
  const { addUpdateEmailToast } = useContext(EmailVerificationContext);
  const { data: ssoPropsData, loading: ssoPropsLoading } = useCommunitySsoProperties(module);
  const client = useApolloClient();
  const uidSeed = useUIDSeed();
  const { router } = useEndUserRoutes();
  const { getCaseSensitivePath } = useSeoProperties();
  const { getPostPageRoute } = usePostPageRoute();
  const ssoEnabled = checkPolicy(ssoPropsData?.community?.ssoProperties?.ssoEnabled);
  const { triggerAuthFlow } = useAuthFlow();
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.NODE_ACTION_BUTTON_WIDGET
  );
  const [joinNode] = useMutationWithTracing<JoinNodeMutation>(module, joinNodeMutation);
  const { showSsoRegistrationModal } = useContext(SsoRegistrationContext);
  const boardId = useRef('');
  const categoryId = useRef('');
  const groupHubId = useRef('');

  boardId.current = isNodeBoard(contextNode) ? contextNode.displayId : null;
  categoryId.current = isNodeBoard(contextNode)
    ? contextNode.parent?.displayId ?? contextNode.parent.displayId
    : null;

  const [propsLoading, finalProps] = useActionButtonWidgetFinalProps(props);
  const { actionButtonTitle, buttonWidth, buttonAlignment } = finalProps;

  const canUseButton = isNodeAbleToDisplayActionButton(
    contextNode,
    userContext,
    registrationStatus,
    confirmEmailStatus
  );

  const redirectToCreatePost = useCallback(async () => {
    const currentBoardId = boardId.current;
    const currentCategoryId = categoryId.current;
    const { messagePostPage } = ConversationStyleBehaviorHelper.getInstance(contextNode as Board);
    await router.pushRoute<MessagePostPagesAndParams>(messagePostPage, {
      boardId: getCaseSensitivePath(currentBoardId),
      categoryId: getCaseSensitivePath(currentCategoryId)
    });
  }, [contextNode, router, getCaseSensitivePath]);

  const variables: GroupHubDetailsQueryVariables = {
    id: contextNode.id,
    useGroupHubDescendants: true,
    useMembershipInformation: true,
    useGroupHubPolicies: true
  };
  const {
    queryResult: { data },
    loading: groupHubInfoLoading
  } = useGroupHubDetails(variables, contextNode.nodeType !== NodeType.GROUPHUB);

  if (groupHubInfoLoading || propsLoading || ssoPropsLoading || textLoading || !canUseButton) {
    return null;
  }

  const groupHub = data?.groupHub;

  if (isNodeGroupHub(contextNode)) {
    const {
      userContext: { canUpdateNode }
    } = contextNode;
    const { isMember, descendants } = groupHub;
    const { edges } = descendants || {};
    const forum = (edges as Array<CoreNodeEdge>)?.find(
      edge => (edge.node as Board).conversationStyle === ConversationStyle.Forum
    )?.node;
    if (forum && (isMember || canUpdateNode)) {
      boardId.current = forum.displayId;
      groupHubId.current = contextNode.displayId;
    }
  }

  /**
   * Renders error feedback in a banner toast when an error occurs while taking an action
   */
  function renderError(): void {
    const uid = uidSeed('error-join-grouphub');
    const toastProps: ToastProps = {
      toastVariant: ToastVariant.BANNER,
      alertVariant: ToastAlertVariant.DANGER,
      title: formatMessage('errorHeader'),
      autohide: true,
      message: formatMessage('errorMessage'),
      delay: 4000,
      id: uid
    };
    addToast(toastProps);
  }

  /**
   * Function to handle errors resulting from a graphQL mutation call
   *
   * @param rollback rollback function passed from the GraphQL mutate's update method
   * @param errors errors (if any) from GraphQL call
   */
  function handleError(rollback: () => void, errors: readonly GraphQLFormattedError[]): void {
    log.error('Unable to join Group Hub: %O', errors);
    if (rollback) {
      rollback();
    }
    renderError();
  }

  /**
   * Renders success feedback in a flyout toast when action is successful
   */
  function renderSuccessFeedback(): void {
    const uid = uidSeed('success-join-grouphub');
    const toastProps: ToastProps = {
      toastVariant: ToastVariant.FLYOUT,
      alertVariant: ToastAlertVariant.SUCCESS,
      title: formatMessage('join.successHeader'),
      autohide: true,
      message: formatMessage('join.successMessage'),
      delay: 4000,
      id: uid
    };
    addToast(toastProps);
  }
  /**
   * Event handler for Join Group Hub action
   */
  async function onJoinMembershipNodeHandler(): Promise<void> {
    const mutationPayload: JoinNodeMutationVariables = {
      nodeId: contextNode.id
    };
    const { errors, rollback } = await updateNode(tenant, {
      nodeId: contextNode.id,
      mutate: joinNode,
      membershipInformation: groupHub,
      action: MembershipAction.JOIN_MEMBERSHIP_NODE,
      mutationPayload,
      client
    });
    if (errors?.length > 0) {
      handleError(rollback, errors);
      return;
    }
    renderSuccessFeedback();
  }

  async function handleUserFlow(): Promise<void> {
    if (!confirmEmailStatus) {
      addUpdateEmailToast();
    } else {
      await redirectToCreatePost();
    }
  }

  async function handleSsoFlow(): Promise<void> {
    if (isAnonymous) {
      const { messagePostPage } = ConversationStyleBehaviorHelper.getInstance(contextNode as Board);
      await triggerAuthFlow(
        () => {},
        AuthFlow.LOGIN,
        router.getCurrentRouteAndParams(),
        getPostPageRoute(boardId?.current, categoryId?.current, messagePostPage)
      );
    }
    if (isPartiallyRegistered) {
      showSsoRegistrationModal(true);
    }

    if (isFullyRegistered) {
      redirectToCreatePost();
    }
  }

  async function onCreatePostActionHandler(): Promise<void> {
    if (boardId) {
      if (isNodeBoard(contextNode) && categoryId) {
        if (ssoEnabled) {
          handleSsoFlow();
          return;
        }
        if (isAnonymous) {
          showAuthenticationModal(AuthFlow.LOGIN, {
            route: EndUserPages.PostPage,
            params: {
              categoryId: categoryId,
              boardId: boardId
            }
          });
        } else {
          await handleUserFlow();
        }
      }
      if (isNodeGroupHub(contextNode) && groupHubId.current) {
        await router.pushRoute<GroupHubMessagePostPagesAndParams>(EndUserPages.GroupHubPostPage, {
          groupHubId: getCaseSensitivePath(groupHubId.current),
          boardId: getCaseSensitivePath(boardId.current)
        });
      }
    } else {
      log.error(
        'Error in trying to create a post: %s %s',
        !categoryId.current ? 'undefined categoryId' : '',
        !boardId.current ? 'undefined boardId' : ''
      );
    }
  }

  /**
   * Function to determine required action when action button is clicked
   */
  function handleClick(): void {
    if (
      isNodeGroupHub(contextNode) &&
      groupHub.membershipType === MembershipType.Open &&
      checkPolicy(groupHub.membershipPolicies.canJoin)
    ) {
      onJoinMembershipNodeHandler();
    } else {
      onCreatePostActionHandler();
    }
  }

  return (
    <EditableWidget<NodeActionButtonProps> props={finalProps}>
      <div className={cx(`lia-align-${buttonAlignment}`)}>
        <Button
          className={cx({
            'size-hero': finalProps.buttonStyle === ButtonStyle.LARGE
          })}
          size={
            finalProps.buttonStyle === ButtonStyle.MEDIUM ||
            finalProps.buttonStyle === ButtonStyle.LARGE
              ? 'lg'
              : undefined
          }
          block={buttonWidth === ButtonSize.FULL_WIDTH}
          variant={ButtonVariant.PRIMARY}
          onClick={(): void => handleClick()}
          data-testid="Node.ActionButton"
        >
          {actionButtonTitle}
        </Button>
      </div>
    </EditableWidget>
  );
};

export default NodeActionButtonWidget;
